import '/assets/styles/partials/rating-box.scss';

import { useState, useEffect } from 'react'
import PropTypes from 'prop-types';

// Constants
const defaultRating = 0;

const computeStars = (rating) => {
	let stars = [];

	for(let k = 1; k < 6; k++) {
		const diff = k - rating;
		stars.push(diff <= 0 ? 100 : (diff < 1 ? (diff * 100) : 0));
	}

	return stars;
}

const RatingBox = ({ className, big, input, onChange, onClick, ...props }) => {
	const [rating, setRating] = useState(props.rating ? props.rating : defaultRating);
	const [displayRating, setDisplayRating] = useState(rating);
	const [starInfo, setStarInfo] = useState(computeStars(displayRating));

	useEffect(() => {
		setStarInfo(computeStars(displayRating));
	}, [displayRating])

	useEffect(() => {
		if(onChange) {
			onChange(rating);
		}
	}, [rating, onChange])

	useEffect(() => {
		setRating(props.rating ? props.rating : defaultRating);
	}, [props.rating])

	return (
		<div
			onMouseLeave={input ? () => {
				setDisplayRating(rating);
			} : undefined}
			className={'rating-box ' + (big ? 'big ' : '') + (input ? ' input ' : '') + className}>
			{starInfo.map((ratio, nth) => (
				<button
					onMouseEnter={input ? () => {
						setDisplayRating(nth + 1);
					} : undefined}
					onClick={input ? () => {
						setRating(nth + 1);
						setDisplayRating(nth + 1);
						if(onClick) {
							onClick(nth + 1);
						}
					} : undefined}
					key={nth}
					type="button"
					className={'box-star' + (ratio === 100 ? ' full' : '')}>
					{ratio !== 100 &&
						<span className="star-ratio" style={{ width: `${ratio}%` }}></span>
					}
				</button>
			))}
		</div>
	)
}

RatingBox.defaultProps = {
	className: '',
	rating: defaultRating,
	big: false,
	input: false,
}

RatingBox.propTypes = {
	className: PropTypes.string,
	big: PropTypes.bool,
	input: PropTypes.bool,
	onClick: PropTypes.func,
	onChange: PropTypes.func,
	rating: PropTypes.number,

}

export default RatingBox