import '/assets/styles/partials/simplebar.scss';
import PropTypes from 'prop-types';

import loadable from '@loadable/component';

const LazySimplebar = loadable(() => import('simplebar-react'), { ssr: false });

const Slider = (props) => {
	return (
		// <SimplebarPlaceholder {...props} />
		<LazySimplebar {...props} fallback={<SimplebarPlaceholder {...props} />} />
	)
}

const SimplebarPlaceholder = ({ className = '', children }) => {
	return (
		<div className={`placeholder ${className}`}>
			{children}
		</div>
	)
}

SimplebarPlaceholder.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
}

export default Slider;