import '/assets/styles/partials/empty-message.scss';

// Partials
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

const EmptyMessage = ({ className, children, icon, lined }) => (
	<div className={`emptymessage ${className}${lined ? ' lined' : ''}`}>
		{icon &&
			<Icon className="emptymessage-icon" name={icon} />
		}
		<div className="emptymessage-content">{children}</div>
	</div>
)

EmptyMessage.defaultProps = {
	className: '',
	icon: 'frown',
}

export default EmptyMessage