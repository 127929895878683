import '/assets/styles/pages/product-comparison.scss';
import React, { useState, useEffect } from 'react'

// Partials
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Loader from '/views/partials/loader'
import Pricebox from '/views/partials/pricebox'
import Btn from '/views/partials/btn'
import Slider from '/views/partials/slider'
import RatingBox from '/views/partials/rating-box'
import EmptyMessage from '/views/partials/empty-message'
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

// Sections
import Breadcrumbs from '/views/sections/breadcrumbs'

// Services
import productServices from '/services/product'

// Context
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';
import { useBreakpoints } from "@wearetla/tla-essentials-tools/utilities/breakpoints";
import formatRelativeWithOptions from 'date-fns/esm/fp/formatRelativeWithOptions/index.js';

const ProductComparison = () => {
	const { openModal } = useModals();
	const { setError } = useGlobalState();
	const { mobile } = useBreakpoints();

	const [sliderInstance, setSliderInstance] = useState(null);
	const [[sliderAtStart, sliderAtEnd], setSliderBoundaries] = useState([true, false]);
	const [products, setProducts] = useState(false);
	const [properties, setProperties] = useState(false);

	const removeProduct = (id) => {
		openModal('confirm', {
			message: `Bu ürünü karşılaştırma listenizden çıkarmak istediğinizden emin misiniz?`,
			onConfirm: () => {
				let newProducts = [];
		
				for(const prod of products) {
					if(prod.id !== id) {
						newProducts.push(prod);
					}
				}
		
				setProducts(newProducts);
				window.localStorage?.setItem('comparison-products', JSON.stringify(newProducts));
			},
		})
	}
	
	useEffect(() => {
		const comparisonData = window.localStorage?.getItem('comparison-products');
		let parsedProductData = [];
		try {
			parsedProductData = comparisonData ? JSON.parse(comparisonData) : [];
		}
		catch(e) {
			window.localStorage?.removeItem('comparison-products');
		}

		if(parsedProductData && parsedProductData.length > 0) {
			productServices.compareProductProperties(parsedProductData.map(p => p.id)).then((payload) => {
				setProducts(payload.products);
				setProperties(payload.properties);

				window.localStorage?.setItem('comparison-products', JSON.stringify(payload.products));
			}).catch(() => {
				setError();
			})
		}
		else {
			setProducts([]);
			setProperties([])
		}
		
	}, [])

	// useEffect(() => {
	// 	if(products && products.length) {
			
	// 	}
	// }, [products])

	const scrollContent = (direction) => {
		if(sliderInstance) {
			if(direction === 'next') {
				sliderInstance.slideNext();
			}
			else {
				sliderInstance.slidePrev();
			}
		}
	}

	const sliderBoundariesChanged = (instance) => {
		setSliderBoundaries([instance.isBeginning, instance.isEnd]);
	}

	const isEmpty = !(products && products.length > 0 && properties);

	return (
		<>
			<Breadcrumbs data={[{ name: 'Ürün Karşılaştırma' }]} />
			
			<div className="page product-comparison">
				<div className="comparison-wrap wrapper">
					<main className={`comparison-container${isEmpty ? ' empty' : ''}`}>
						{!mobile &&
							<aside className="comparison-legend">
								<div className="legend-header">
									<Icon name="comparison" className="header-icon" />
									<strong className="header-title">
										Karşılaştırma <br />Listem
									</strong>
									<span className="header-count">({products.length} ürün)</span>
								</div>

								{!isEmpty &&
									<>
										<strong className="legend-slot info">Ürün Bilgisi</strong>
										<strong className="legend-slot rating">Değerlendirme</strong>
										<strong className="legend-slot property">Ürün Kodu</strong>
										<strong className="legend-slot property">Marka</strong>
										<strong className="legend-slot property">Cinsiyet</strong>
										<strong className="legend-slot property">Üretim Yeri</strong>
										<strong className="legend-slot property">Barkod</strong>
										{properties &&
											<>
												{properties.map((property, nth) => (
													<strong className="legend-slot property" key={nth}>{property.key}</strong>
												))}
											</>
										}
									</>
								}
							</aside>
						}
						<div className="comparison-content">
							<div className="content-header">
								<h1 className="header-title">
									Ürün Karşılaştır

									{mobile &&
										<span className="title-count">({products.length} ürün)</span>
									}
								</h1>
								<div className="header-scrollnav">
									<button
										disabled={sliderAtStart || !products || (products && products.length === 0)}
										type="button"
										onClick={() => {
											scrollContent('prev')
										}}
										className="scrollnav-btn prev">
										<Icon name="arrow-left" />
									</button>
									<button
										disabled={sliderAtEnd || !products || (products && products.length === 0)}
										type="button"
										onClick={() => {
											scrollContent('next')
										}}
										className="scrollnav-btn next">
										<Icon name="arrow-right" />
									</button>
								</div>
							</div>

							<div className="comparison-productcontainer loader-container">
								<Loader loading={!products || !properties} />

								{!isEmpty &&
									<Slider
										params={{
											onSwiper: setSliderInstance,
											onBeforeDestroy: () => { setSliderInstance(null); },
											onToEdge: sliderBoundariesChanged,
											onFromEdge: sliderBoundariesChanged,
											slidesPerView: 'auto',
											freeMode: true,
											mousewheel: {
												forceToAxis: true,
											},
											direction: 'horizontal',
											scrollbar: {
												"hide": true
											},
											loop: false,
										}}
										className="comparison-productlist">
										{products.map((product, nth) => (
											<div className={'productlist-item' + (!product ? ' empty' : '')} key={product ? product.id : `_${nth}`}>
												<div className="item-info">
													<Img
														className="info-image"
														src={product.image}
														title={product.name} />
													<div className="info-text">
														<p className="info-name">
															{product.name}
														</p>
														<Pricebox
															className="info-price"
															product={product} />
													</div>
													<div className="info-controls">
														<Btn
															className="primary"
															tag="link"
															params={{
																id: product.id,
																slug: product.slug,
															}}
															href="productDetail">
															Ürüne Git
														</Btn>
														<Btn
															className="rounded outline"
															onClick={() => { removeProduct(product.id) }}
															icon="trash" />
													</div>
												</div>
												<div className="item-rating">
													<strong className="rating-title">Değerlendirme ({product.comment_count ? product.comment_count : 0})</strong>
													<RatingBox big rating={product.comment_average ? product.comment_average : 0} />
												</div>
												<ul className="item-properties">
													<li className="properties-row">
														{product.barcode ? product.barcode : '-'}
													</li>
													<li className="properties-row">
														{product.brand ? product.brand : '-'}
													</li>
													<li className="properties-row">
														{product.gender_name ? product.gender_name : '-'}
													</li>
													<li className="properties-row">
														{product.madein ? product.madein : '-'}
													</li>
													<li className="properties-row">
														{product.product_code ? product.product_code : '-'}
													</li>
													{properties &&
														<>
															{properties.map((property, nth) => (
																<li className="properties-row" key={nth}>
																	{mobile &&
																		<strong className="row-title">{property.key}</strong>
																	}
																	{property.params[product.id] ? property.params[product.id] : '-'}
																</li>
															))}
														</>
													}
												</ul>
											</div>
										))}
									</Slider>
								}
								{isEmpty &&
									<EmptyMessage className="comparison-emptymessage">
										Listenizde karşılaştırmak için ürün bulunmuyor.
									</EmptyMessage>
								}
							</div>
						</div>
					</main>
				</div>
			</div>
		</>
	)
}

export default ProductComparison;