import '/assets/styles/sections/breadcrumbs.scss';
import classNames from 'classnames';
import { Fragment } from 'react'
import PropTypes from 'prop-types';

// Partials
import Simplebar from '/views/partials/simplebar';
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Placeholder from '/views/partials/placeholder'

// Context
import { useApplication } from '/utilities/application';
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';

const Breadcrumbs = ({ data, className }) => {
	const { isApplication } = useApplication();
	const { mobile } = useBreakpoints();

	if(isApplication && mobile) {
		return null;
	}

	return (
		<div className={classNames('section', 'breadcrumbs', 'wrapper', className, { 'type-app': isApplication})}>
			<div className="breadcrumbs-outerwrap">
				<Simplebar
					className="breadcrumbs-wrap">
					<ul className="breadcrumbs-list">
						<li className="breadcrumbs-item">
							<Link className="item-link" href="home">
								Anasayfa
							</Link>
						</li>
						{data !== false ?
							<>
								{data.map((crumb, nth) => (
									<Fragment key={nth}>
										{crumb &&
											<li className="breadcrumbs-item" key={nth}>
												{crumb.href ?
													<Link
														className="item-link"
														href={crumb.href}
														params={crumb.params}>
														{crumb.name}
													</Link>
													:
													<>{crumb.name}</>
												}
											</li>
										}
									</Fragment>
								))}
							</>
							:
							<>
								<li className="breadcrumbs-item">
									<Placeholder className="item-link" />
								</li>
							</>
						}
					</ul>
				</Simplebar>
			</div>
		</div>
	)
}

Breadcrumbs.propTypes = {
	data: PropTypes.oneOfType([ PropTypes.array, PropTypes.oneOf([false]) ]),
	className: PropTypes.string,
}

Breadcrumbs.defaultProps = {
	data: false,
	className: '',
}

export default Breadcrumbs;